#form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
.validation-message {
  &__item {
    color: red;
  }
}
