.bulk-credit-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  .form-container {
    width: 65%;
    margin-right: 20px;

    .action-container {
      background-color: #fff;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: 20px;
      padding: 10px;

      .run-free-credit {
        margin-right: 10px;
      }
    }
  }
}

.result-container {
  width: 35%;

  .response-textarea {
    font-family: monospace;
    white-space: pre;
    overflow-x: scroll;
    background-color: #eee !important;
    padding: 5px;
  }
}

.form-label {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

.checkbox-label {
  margin-left: 10px;
  font-weight: normal;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.user-list-textarea {
  font-family: monospace;
  white-space: pre;
  overflow-x: scroll;
  background-color: #eee !important;
  padding: 5px;
}

.partially-registered-alert {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
}