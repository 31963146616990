.heading {
  font-family: inherit;
  color: #333;
  margin-bottom: 1rem;
  // marked the declaration as important to
  // override DevExtreme style for the heading elements
  &--1 {
    font-size: 2rem !important;
    font-weight: bold !important;
  }
  &--2 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
  }
  &--3 {
    font-size: 1.17rem !important;
    font-weight: bold !important;
  }
  &--4 {
    font-size: 1.33rem !important;
    font-weight: bold !important;
  }
  &--5 {
    font-size: 0.83rem !important;
    font-weight: bold !important;
  }
  &--6 {
    font-size: 0.63rem !important;
    font-weight: bold !important;
  }
}
