@import "./variables";

@mixin desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin tablet{
    @media screen and (min-width: $tablet) {
        @content;
    }
}