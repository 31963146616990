.transaction-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-right: 20px;

    .balance-container {
      display: flex;
      align-items: center;
      margin-left: 20px;

      p {
        font-size: 16px;
        margin-right: 10px;
      }

      .addNewCustomer {
        color: white !important;
      }
    }
  }


  .transaction-search {
    display: flex;

    .search-field {
      margin-right: 10px;
    }

    .search-button {
      background-color: #337ab7;
      border-color: transparent;
      margin-bottom: 10px;
      margin-top: 10px;
      color: #fff;
      &:hover {
        background-color: #296293;
        border-color: transparent;
        color: #fff;
      }
    }
  }
}