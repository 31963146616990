.simulation-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  .form-container {
    width: 65%;
    margin-right: 20px;

    .action-container {
      background-color: #eee;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: 20px;
      padding: 10px;

      .run-simulator {
        margin-right: 10px;
      }

      .update-simulator {
        margin-right: 10px;
      }
    }
  }
}

.bets-container {
  width: 35%;

  .bets-textarea {
    font-family: monospace;
    white-space: pre;
    overflow-x: scroll;
    background-color: #eee !important;
    padding: 5px;
  }
}

.form-label {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}