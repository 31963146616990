.dashboard-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    .datebox-from {
        margin-right: 10px;
    }
    .datebox-to {
        margin-right: 10px;
    }
}