.lefttextboxContainer
{
  width: 100%;
  padding: 1%;
  text-align: left;
}
.textboxContainer>.dx-button
{  
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #337ab7 !important;
  border-color: transparent;
  color: #fff;
}
.textboxContainer>.dx-button:hover
{
  background-color: #296293 !important;
}
.addUser{
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
}
.addUser:hover{
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #296293;
  border-color: transparent;
  color: #fff;
}