$desktop: 1000px;
$tablet: 800px;

$gap: 1em;
$padding-15 : 15px;

$radius-text-box : 5px;
$radius-btn : 9px;

$base-img-path: "/Assets/images/";
$base-img-icon-path: "/Assets/icons/";

@function getIcon($icon-name){
    @return $base-img-icon-path + $icon-name;
}

@function getImage($image-name){
    @return $base-img-path + $image-name;
} 
