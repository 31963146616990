.title {
  font-style: bold;
}
.popup-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1em;
}
.btn{
 padding-top: 15px; 
 width: 100%;
 text-align: right; 
}
.ok {
  font-size: 15px;
  color: rgb(0, 88, 0);
  background-color:rgb(214, 236, 212); 
}
.notok{
  color: rgb(184, 5, 5);
  background-color:rgb(243, 188, 188); 
  font-size: 15px;
}
a {
  text-decoration: none;
}
.newDomain::after {
  color: rgb(177, 0, 15);
  font-size: xx-small;
  vertical-align: text-top;
  content: "New ";
}
.newDomain {
  font-weight: bold;
  a {
    color: rgb(177, 0, 15);
  }
}

.checked {
  color: green;
}
.notChecked {
  color: rgb(177, 0, 15);
}
.textboxContainer
{
  width: 100%;
  padding: 1%;
  text-align: right;
}
.textboxContainer>.dx-button
{
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #337ab7 !important;
  border-color: transparent;
  color: #fff;
}
.textboxContainer>.dx-button:hover
{
  background-color: #296293 !important;
}
.addUser{
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
}
.addUser:hover{
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #296293;
  border-color: transparent;
  color: #fff;
}
.taxonomy-list {
  list-style-position: inside;
  list-style-type: disclosure-closed;
  padding: 0;
  margin-top: 0;
}
.addButton>div>div{
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
}

.addButton>div>div:hover{
  background-color: #296293;
  border-color: transparent;
  color: #fff;
}
.addButton>div>div>div>i{
  display: none !important;
}

.addRole{
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: #337ab7 !important;
    border-color: transparent;
    color: #fff !important;
}