.addCredit {
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
  margin: 10px 0 10px 0;

  &:hover {
    background-color: #296293;
    border-color: transparent;
    color: #fff;
  }
}

.credit-popup-container {
  display: grid;
  grid-template-columns: 0.5fr 1.25fr 1.25fr;
  margin-bottom: 10px;

  .credit-title {
    margin-top: 5px;
    margin-right: 10px;
  }
}