@import '../../styles/colors';

.main-container {
  // border: 1px solid #cccccc;
  & .dx-drawer-panel-content {
    height: inherit;
    background-color: color('dashboard-bg');
    box-shadow: 2px 0px 4px -2px rgba(0, 0, 0, 1);
    height: 100%;
    min-height: 100vh;
  }
  & .dx-treeview-node {
    padding-left: 0;
  }
  & .dx-treeview .dx-scrollable-wrapper {
    color: color('white-color');
  }
  & .dx-drawer-shrink .dx-drawer-panel-content {
    height: auto;
  }
  & .dx-treeview-toggle-item-visibility {
    left: unset;
    right: 0;
    padding: 0 0.8rem;
    &::before {
      color: color('white-color');
      font-size: 1.5rem;
    }
  }
  & .dx-treeview-node-container {
    & .dx-treeview-node:not(:first-child) {
      border-top: 1px solid color('header-bg');
    }
    & .dx-treeview-node-container .dx-treeview-item-without-checkbox {
      & .dx-item {
        margin-left: 22px;
      }
    }
    & .dx-item-content {
      padding-left: 14px;
    }
    & .dx-state-selected {
      background-color: color('dark-dashboard-bg');

      & .dx-item-content {
        color: color('white-color');
      }
      & .dx-treeview-item {
        background-color: color('dark-dashboard-bg');
      }
      & .dx-icon-publisher,
      .dx-icon-reports-icon,
      .dx-icon-transactions-icon,
      .dx-icon-pricing-icon,
      .dx-icon-content-agent,
      .dx-icon-client-orders {
        background-color: color('white-color');
      }
    }
  }

  .dx-state-hover {
    .dx-icon-publisher,
    .dx-icon-reports-icon,
    .dx-icon-transactions-icon,
    .dx-icon-pricing-icon,
    .dx-icon-content-agent,
    .dx-icon-client-orders {
      background-color: #333;
    }
  }
}
.aside-container {
  height: 100%;
  max-height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  &__logo {
    width: 250px;
    display: block;
    padding: 1rem 20px;
    margin-bottom: 3rem;
    // filter: drop-shadow(1px 1px 1px rgba(0, 0, 0));
  }
}
.main-app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.aside-logo-text {
  font-weight: bold !important;
  color: #fff !important;
  font-size: 2rem !important;
  text-align: center !important;
  margin-bottom: 1rem !important;
}
.dx-icon-reports-icon {
  background-color: color('white-color');
  -webkit-mask-image: url(../../Assets/images/ui/reports-icon.svg);
  mask-image: url(../../Assets/images/ui/reports-icon.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
