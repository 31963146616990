.reset-password {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 380px;
  margin: 0 auto;
  padding-top: 2rem;
  justify-content: center;
  &__input {
    margin-bottom: 1rem;
  }
}
