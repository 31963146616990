.rule-container {
  margin-top: 1rem;

  .rule-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .rule-edit-alert {
    background-color: #fff3cd;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }

  .rule-form-container {
    .rule-name-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .expression-textarea {
      font-family: monospace;
      white-space: pre;
      overflow-x: scroll;
      padding: 5px;
    }
  }
}

.no-rule-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 3rem;
  color: #2d6da3;
}

.font-1rem {
  font-size: 1rem;
}

.font-success {
  color: #4caf50;
}

.form-label {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}