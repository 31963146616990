.header {
  padding: 10px 20px;
  background-color: hsla(0, 0%, 75%, 0.15);
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.45);
  &__welcome-msg {
    margin: 0;
  }
  &__logout-btn {
    font-weight: 600 !important;
    margin-left: 1rem;
  }
  &__logo {
    width: 200px;
    padding: 0 1rem;
    box-sizing: content-box;
    display: flex;
    align-items: center;
  }
  &__list {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  &__item {
    display: flex;
    align-items: center;
  }
}
