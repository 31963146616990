@import '../../styles/colors';
.title {
  font-style: bold;
}

.overview {
  background-color: rgb(238, 235, 235);
}

.popup-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1em;
}
.btn {
  padding-top: 15px;
  width: 100%;
  text-align: right;
}
.ok {
  font-size: 15px;
  color: rgb(0, 88, 0);
  background-color: rgb(214, 236, 212);
}
.notok {
  color: rgb(184, 5, 5);
  background-color: rgb(243, 188, 188);
  font-size: 15px;
}
a {
  text-decoration: none;
}
.newDomain::after {
  color: rgb(177, 0, 15);
  font-size: xx-small;
  vertical-align: text-top;
  content: 'New ';
}
.newDomain {
  font-weight: bold;
  a {
    color: rgb(177, 0, 15);
  }
}

.checked {
  color: green;
}
.notChecked {
  color: rgb(177, 0, 15);
}
.textboxContainer {
  width: 100%;
  padding: 1%;
  text-align: right;
}

.addNewUser {
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
}
.addNewUser:hover {
  background-color: #296293;
  border-color: transparent;
  color: #fff;
}
.taxonomy-list {
  list-style-position: inside;
  list-style-type: disclosure-closed;
  padding: 0;
  margin-top: 0;
}
.addButton > div > div {
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
}

.addButton > div > div:hover {
  background-color: #296293;
  border-color: transparent;
  color: #fff;
}
.addButton > div > div > div > i {
  display: none !important;
}
.dx-column-lines {
  &.selected {
    background-color: color('lockout-color');
  }
}
.lockout-user {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}
.accessFailedCount {
  display: flex;
  justify-content: center;
}
.enable-btn {
  cursor: pointer;
}
.email-column {
  display: flex;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.user-filter {
  max-width: 20rem;
  margin: 0.5rem;
}

.user-filter-container {
  display: flex;
  align-items: center;
  //justify-content: center;
}

.user {
  &__fieldset {
    padding: 0.5rem;
  }
  &__roles {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  &__role {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
  }
}
input:disabled {
  cursor: not-allowed;
}

.addNewBackendUser {
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
    &:hover {
        background-color: #296293;
        border-color: transparent;
        color: #fff;
    }
}
