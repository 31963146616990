.hamburger-button {
  border: transparent;
  cursor: pointer;
  background-color: transparent;
  display: block;
  height: 22px;
  width: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0;
  box-sizing: content-box;
  &--open {
    & .hamburger-button__line--1 {
      transform: rotate(45deg) translate(-2px, -2px);
    }
    & .hamburger-button__line--2 {
      transform: scaleY(0);
    }
    & .hamburger-button__line--3 {
      transform: rotate(-45deg);
    }
  }

  &__line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #595959;
    &--1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }
    &--2 {
      transition: transform 0.2s ease-in-out;
    }

    &--3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
  }
}

.nav-container input[type='checkbox']:checked ~ .menu-items {
  transform: translateX(0);
}
