.topup {
  padding-right: 3rem;
  &__filter {
    margin: 1rem 0;
  }
  &__filter-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr auto;
    align-items: start;
    & > * {
      // height: 33px;
    }
    gap: 1.5rem;
  }
  &__grid {
    margin-top: 1.5rem;
  }
}
