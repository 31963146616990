.addBlackout {
  background-color: #337ab7;
  border-color: transparent;
  color: #fff;
  margin: 10px 0 10px 0;
  &:hover {
    background-color: #296293;
    border-color: transparent;
    color: #fff;
  }
}