@import '../../styles/colors';

.grid {
  &__header {
    margin: 10px 0px;
  }
}
$grid-border-width: 1.6px;
$border-color: hsla(0, 0%, 17%, 0.5);
.grid-table,
.dx-gridbase-container {
  // .dx-datagrid-nodata {
  //   font-size: 2rem;
  //   font-weight: 500;
  //   color: color('dashboard-bg');
  //   margin: 1.5rem 0 0;
  // }
  .dx-datagrid-rowsview {
    border: unset;
  }
  .dx-datagrid-borders > .dx-datagrid-filter-panel,
  .dx-datagrid-borders > .dx-datagrid-headers {
    border: unset;
  }

  .dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
    .dx-datagrid-borders > .dx-datagrid-filter-panel,
    .dx-datagrid-borders > .dx-datagrid-headers {
      border: unset;
    }
    .dx-row.dx-header-row {
      border: $grid-border-width solid $border-color;
      border-bottom-color: $border-color;
      border-top-color: $border-color;
      background: color('dark-gray');
      color: color('border-color');
      font-weight: 600;
    }
  }
  .dx-datagrid-content {
    tbody > tr {
      border: $grid-border-width solid $border-color;
      border-bottom-color: $border-color;
      &:nth-child(even) {
        background: rgba(236, 235, 235, 0.7);
        color: color('border-color');
      }
      & > td:not(:last-child) {
        border-right: $grid-border-width solid $border-color;
      }
      &:first-child {
        border-top: unset;
      }
    }
  }
  & .dx-datagrid-table .dx-header-row {
    & td.dx-command-expand {
      border-right: none !important;
      vertical-align: top;
    }
  }
  & .dx-datagrid-content .dx-row.dx-freespace-row {
    border: none;
    background: white;
    & td {
      border: none;
    }
  }
}
